<template>
  <div class="bg-neutral-50 aspect-[16/10] shared-picture-carousel">
    <swiper
      :modules="modules"
      :style="{
        '--swiper-pagination-color': '#fff',
        '--swiper-navigation-color': '#3c3c3c',
        '--swiper-pagination-bullet-size': '10px',
        '--swiper-pagination-bullet-width': '10px',
        '--swiper-pagination-bullet-height': '10px',
        '--swiper-navigation-size': '20px',
        '--swiper-navigation-sides-offset': '2rem',
        '--swiper-navigation-top-offset': '50%'
      }"
      :slides-per-view="1"
      :space-between="0"
      :pagination="{dynamicBullets: true}"
      :navigation="arrows ? {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      } : false"
      :loop="true"
      :lazy="true"
      direction="horizontal"
      :nested="nestedCarousel"
      class="h-full"
    >
      <swiper-slide v-for="(bild, index) in props.item.bilder_neu" :key="index" class="h-full w-full">
        <img :src="bild" :loading="lazyLoading ? 'lazy' : 'eager'" :alt="props.item?.alt || ''" class="object-cover object-center h-full w-full bg-neutral-50">
      </swiper-slide>
      <div v-if="arrows" class="swiper-button-next" @click.stop.prevent />
      <div v-if="arrows" class="swiper-button-prev" @click.stop.prevent />
    </swiper>
  </div>
</template>

<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Pagination, Navigation } from 'swiper/modules'
import 'swiper/css'
const arrows = ref(true)

const props = defineProps({
  item: {
    type: Object,
    default: null
  },
  lazyLoading: {
    type: Boolean,
    default: true
  },
  nestedCarousel: {
    type: Boolean,
    default: false
  }
})
const modules = [Pagination, Navigation]

const carouselSetting = reactive({
  isMobile: true
})

if (import.meta.client) {
  carouselSetting.isMobile = window.navigator.userAgent.match(/Android|iPad|iPhone/) || ''
  if (carouselSetting.isMobile) {
    arrows.value = false
  }
}
</script>
<style lang="scss" src="./scss/SharedPictureCarousel.scss"></style>
