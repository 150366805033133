<template>
  <UiMaskedLink
    :href="objectUrl()"
    :new-tab="isNewTab"
    :masking-disabled="!maskedLink"
    :masking-aria-label="t('aria_label_object_card', { hausname: props.item?.hausname, haustyp: props.item?.haustyp })"
    masking-disabled-css="h-full flex flex-col"
    :external="true"
    :class="[
      {'bg-white shadow-md rounded-xl focus:outline-none border border-neutral-100': searchresult || normal || wishlist},
      {'bg-white shadow-md rounded-xl focus:outline-none border border-neutral-50': objectslider},
      {'lg:border-orange-400 lg:outline lg:outline-2 lg:outline-orange-400': props.highlighted || props.serpHighlighted},
      'relative text-left flex flex-col h-full'
    ]"
    @click="tracking"
    @keydown.enter="tracking"
  >
    <div
      v-if="props.highlighted && props.highlightedIconUrl !== ''"
      class="absolute top-0 left-0 -mt-2 -ml-[14px] z-20 invisible lg:visible"
    >
      <img :loading="lazyLoading ? 'lazy' : 'eager'" width="26" height="35" alt="Mapmarker" :src="props.highlightedIconUrl">
    </div>
    <div class="relative">
      <div
        class="absolute top-0 left-0 mt-5 ml-5 z-10"
      >
        <div
          v-if="props.item?.wrong_personen"
          class="bg-neutral-800 text-white text-sm py-1 px-2 rounded-full mb-1"
        >
          <UiLanguagePlaceholder domain="page.merkzettel" name="wrong_filter_personen" />
        </div>
        <div
          v-if="props.item?.wrong_date"
          class="bg-neutral-800 text-white text-sm py-1 px-2 rounded-full"
        >
          <UiLanguagePlaceholder domain="page.merkzettel" name="wrong_filter_date" />
        </div>
      </div>
      <div v-if="!wishlistNone" class="absolute top-0 right-0 mt-3 mr-6 z-10 " @click.stop.prevent>
        <WishlistHeartIcon v-if="wishlistRemove" :object-id="props.item?.nr" wishlist-style="remove" />
        <WishlistHeartIcon v-else :object-id="props.item?.nr" />
      </div>
      <div
        v-if="searchresult && props.item?.buchungssystem == BookingSystemEnum.Onlinebuchung"
        :class="[
          {'opacity-30': filterWishlistVisible },
          'absolute top-0 left-0 mt-5 ml-5 bg-petrol-500 rounded-2xl text-white text-sm uppercase py-1 px-2 z-10'
        ]"
      >
        <UiLanguagePlaceholder domain="SharedObjectCard" name="sofort_buchen" />
      </div>
      <div
        v-if="searchresult && (props.flexdate || (props.item?.variability_from && props.item?.variability_to))"
        :class="[
          {'opacity-30': filterWishlistVisible },
          'absolute bottom-6 left-0 mt-5 px-5 w-full text-center z-10'
        ]"
      >
        <div class="bg-white rounded-2xl inline-block py-1 px-3">
          <UiLanguagePlaceholder
            domain="SearchResults"
            name="variability_example"
            :variables="{ arrival: formatDateWithDay(props.item?.variability_from), departure: formatDateWithDay(props.item?.variability_to) }"
          />
        </div>
      </div>
      <div
        v-if="objectHelper.isNewObject(props.item?.freigeschaltet_seit)"
        :class="[
          {'opacity-30': filterWishlistVisible},
          {'right-0 mr-6': objectHelper.isNewObject(props.item?.freigeschaltet_seit) && (!props.item?.has_lm_boolean_df && !props.lastminute && !totalPriceDiscountPercentage)},
          {'left-0 ml-6': objectHelper.isNewObject(props.item?.freigeschaltet_seit) && (props.item?.has_lm_boolean_df || props.lastminute || totalPriceDiscountPercentage)},
          'absolute bottom-0 mb-3 bg-petrol-500 rounded-lg text-white uppercase py-2 px-3 z-10 '
        ]"
      >
        <UiLanguagePlaceholder domain="SharedObjectCard" name="new" />
      </div>
      <div
        v-if="props.item?.has_lm_boolean_df || props.lastminute || totalPriceDiscountPercentage"
        class="absolute bottom-0 mb-3 right-0 mr-6 bg-cta-700 text-md rounded text-white font-bold text-base py-1 px-1.5 z-10"
      >
        <span>
          <span v-if="totalPriceDiscountPercentage">
            - {{ totalPriceDiscountPercentage }}
          </span>
          %
        </span>
      </div>
      <div
        v-if="portal == 'http://www.sylt-travel.de' && props.item?.exklusiv"
        :class="[
          {'opacity-30': filterWishlistVisible },
          'absolute bottom-0 right-0 bg-neutral-800/95 w-full text-center py-1 px-3 z-10'
        ]"
      >
        <img src="https://img.travanto.de/travanto/svg/logos/logo-Sylt-Exklusiv.svg" alt="Sylt Exclusive" height="24" width="120" class="inline-block">
      </div>
      <div
        v-if="props.item?.bilder_neu && props.builderCarousel"
        :class="[
          {'opacity-30': filterWishlistVisible },
          'w-full overflow-hidden rounded-t-xl relative'
        ]"
      >
        <SharedPictureCarousel :nested-carousel="nestedCarousel" :item="props.item" :lazy-loading="lazyLoading" :class="[ {'rounded-t-xl overflow-hidden' : objectslider } ]" />
      </div>
      <div
        v-else
        :class="[
          {'opacity-30': filterWishlistVisible },
          'w-full overflow-hidden rounded-t-xl'
        ]"
      >
        <img
          :src="props.item?.bilder_neu[0]"
          :alt="props.item?.nr"
          height="auto"
          width="auto"
          :class="[
            {'rounded-t-xl ' : objectslider },
            'aspect-[16/10] object-cover object-center w-full bg-neutral-50'
          ]"
          loading="lazy"
        >
      </div>
    </div>
    <div
      :class="[
        {'px-4 py-2' : searchresult || normal || wishlist },
        {'px-4 pt-2 pb-3' : objectslider },
        {'opacity-30': filterWishlistVisible },
        'flex flex-col flex-1 justify-between'
      ]"
    >
      <div v-if="!objectslider" class="text-lg font-semibold leading-5 mb-2 line-clamp-2">
        {{ props.item?.hausname }}
      </div>
      <div>
        <div class="text-sm line-clamp-2">
          <span v-if="objectslider" class="inline-block mr-1">
            <UiIcon src="/svg/icons/location-typ-2.svg" width="12" height="12" class="fill-petrol-500 flex align-center" />
          </span>
          <span class="text-petrol-500">
            {{ props.item?.region }}
          </span>
          <span> - </span>
          <span>
            {{ props.item?.ortsname }}
          </span>
          <span v-if="objectslider">
            - {{ props.item?.personen }} <UiLanguagePlaceholder domain="SharedObjectCard" name="guests" />
          </span>
          <span v-if="objectslider && props.item?.schlafzimmer > 0">
            - {{ props.item?.schlafzimmer }} <UiLanguagePlaceholder domain="SharedObjectCard" name="bedroom" />
          </span>
          <span v-if="objectslider && Math.round(props.item?.distance) > 0 && props.item?.ortsname != regionDistanceTo.trim()">
            - <UiLanguagePlaceholder domain="SharedObjectCard" name="distance_to_region" :variables="{ distance : Math.round(props.item?.distance), region : regionDistanceTo }" />
          </span>
        </div>
        <div
          :class="[
            {'text-lg font-semibold': objectslider },
            {'text-sm mb-2': !objectslider },
            'line-clamp-1 break-all'
          ]"
        >
          <span>
            {{ props.item?.haustyp }}
            - {{ props.item?.wohnflaeche }} m²
          </span>
          <span v-if="!objectslider">
            - {{ props.item?.personen }} <UiLanguagePlaceholder domain="SharedObjectCard" name="guests" />
          </span>
          <span v-if="!objectslider && props.item?.schlafzimmer > 0">
            - {{ props.item?.schlafzimmer }} <UiLanguagePlaceholder domain="SharedObjectCard" name="bedroom" />
          </span>
        </div>
        <div v-if="objectslider && props.lastminute" class="text-lg font-light">
          <span>{{ props.item?.lastminute?.zeitraum_kurz?.von }} - {{ props.item?.lastminute?.zeitraum_kurz?.bis }}:</span>
          <span class="font-semibold ml-1">{{ props.item?.lastminute?.prozent_ersparnis }}% sparen!</span>
        </div>
        <div v-if="searchresult" class="text-lg font-semibold text-green-500 mb-2">
          <span v-if="props.item?.free_cancellation_until > 0 && props.item?.free_x_days !== null">{{ t('free_cancellation') }}</span>
          <span v-if="props.item?.free_cancellation_until > 0 && props.item?.free_x_days === null" class="text-transparent">Cancellation date passed</span>
          <span v-if="props.item?.free_cancellation_until < 0" class="text-transparent">NO cancellation</span>
        </div>
        <div
          :class="[
            {'flex flex-wrap justify-between items-center' : !totalPrice },
            'gap-x-3'
          ]"
        >
          <div>
            <SharedStars v-if="props.item?.bewertung_durchschnitt" :width="14" :height="14" :rating-text="true" :rating="props.item?.bewertung_durchschnitt" />
            <SharedStars v-if="props.item?.bewertung" :width="14" :height="14" :rating-text="true" :rating="props.item?.bewertung" />
          </div>
          <div
            :class="[
              {'text-neutral-800': props.design && !filterWishlistVisible },
              {'text-left': totalPrice },
              {'text-right': !totalPrice },
              'text-lg'
            ]"
          >
            <span v-if="totalPrice">
              <span
                v-if="totalPriceOriginal"
                class="text-cta-500 text-xs mr-1 line-through"
              >
                {{ totalPriceOriginal }}
              </span>
              <span class="font-semibold mr-1">{{ totalPrice }}</span>
              <UiLanguagePlaceholder domain="SharedObjectCard" name="total_price" />
            </span>
            <span v-else-if="pricePerNight">
              <span class="font-semibold"><UiLanguagePlaceholder domain="SharedObjectCard" name="from" /> {{ pricePerNight }}</span>
              <UiLanguagePlaceholder domain="SharedObjectCard" name="night" />
            </span>
          </div>
        </div>
      </div>
    </div>
  </UiMaskedLink>
</template>

<script setup lang="ts">
import type { ComputedRef } from 'vue'
import { useGuestsStore } from '~~/stores/guests'
import { useTravelDatesStore } from '~~/stores/travelDates'
import BookingSystemEnum from '~~/types/product/BookingSystemEnum'
import usePrices from '~/composables/usePrices'

const props = defineProps({
  item: {
    type: Object,
    default: null
  },
  design: {
    type: String,
    default: 'normal'
  },
  rating: {
    type: Boolean,
    default: true
  },
  onlinebooking: {
    type: Boolean,
    default: false
  },
  storno: {
    type: Boolean,
    default: false
  },
  lastminute: {
    type: Boolean,
    default: false
  },
  syltexclusive: {
    type: Boolean,
    default: false
  },
  flexdate: {
    type: Boolean,
    default: false
  },
  favourite: {
    type: Boolean,
    default: false
  },
  wishlistRemove: {
    type: Boolean,
    default: false
  },
  wishlistNone: {
    type: Boolean,
    default: false
  },
  highlighted: {
    type: Boolean,
    default: false
  },
  highlightedIconUrl: {
    type: String,
    default: ''
  },
  builderCarousel: {
    type: Boolean,
    default: true
  },
  maskedLink: {
    type: Boolean,
    default: true
  },
  wishlistParams: {
    type: Object,
    default: null
  },
  lazyLoading: {
    type: Boolean,
    default: true
  },
  nestedCarousel: {
    type: Boolean,
    default: false
  },
  regionDistanceTo: {
    type: String,
    default: ''
  },
  serpHighlighted: {
    type: Boolean,
    default: false
  }
})

const { translate: t } = useTranslations('SharedObjectCard')

const searchresult = computed(() => props.design === 'searchresult')
const normal = computed(() => props.design === 'normal')
const wishlist = computed(() => props.design === 'wishlist')
const objectslider = computed(() => props.design === 'objectslider')
const filterWishlistVisible = computed(() => props.item?.wrong_personen || props.item?.wrong_date)
const objectHelper = useObjectHelper()
function objectUrl () {
  if (props.wishlistParams !== null && !filterWishlistVisible.value) {
    return props.item?.url + '#von=' + props.wishlistParams.von + '&bis=' + props.wishlistParams.bis + '&personen=' + props.wishlistParams.personen
  }
  if (searchresult.value && (props.flexdate || (props.item?.variability_from && props.item?.variability_to))) {
    return props.item?.url + '#von=' + props.item?.variability_from + '&bis=' + props.item?.variability_to
  }
  return props.item?.url
}
const isNewTab = ref(true)
if (import.meta.client) {
  const userAgent = window.navigator.userAgent
  isNewTab.value = !/(iPad|iPhone)/.test(userAgent)
}

const totalPrice: ComputedRef<string|null> = computed(() => {
  return usePrices().totalPrice(props.item?.gesamtpreis)
})
const totalPriceOriginal: ComputedRef<string|null> = computed(() => {
  return usePrices().totalPriceOriginal(props.item?.gesamtpreis_original, props.item?.gesamtpreis)
})
const totalPriceDiscountPercentage: ComputedRef<string|null> = computed(() => {
  return usePrices().totalPriceDiscountPercentage(props.item?.gesamtpreis_original, props.item?.gesamtpreis)
})
const pricePerNight: ComputedRef<string|null> = computed(() => {
  return usePrices().pricePerNight(props.item?.min, props.item?.lastminute?.tagespreis)
})

function tracking () {
  const { $gtm } = useNuxtApp()
  const guests = useGuestsStore()
  const travelDates = useTravelDatesStore()
  $gtm.productsModule.sendProductClickEvent(props.item, guests.personen, travelDates.arrival, travelDates.departure)
}

const { currentPortal } = usePortals()
const portal = currentPortal.value.portal.portal

const formatDateWithDay = (date: string|null|undefined) => {
  if (!date) {
    return ''
  }
  const portalDe = currentPortal.value?.portal?.lang === 'de'
  const dateObj = new ExtendedDate(date)

  const dayOfWeek = dateObj.toLocaleDateString(
    (portalDe ? 'de-DE' : 'en-GB'),
    { weekday: 'short' }
  )
  const dayOfMonth = dateObj.toLocaleDateString(
    (portalDe ? 'de-DE' : 'en-GB'),
    { day: '2-digit' }
  )
  const month = dateObj.toLocaleDateString(
    (portalDe ? 'de-DE' : 'en-GB'),
    { month: '2-digit' }
  )

  return `${dayOfWeek} ${dayOfMonth}.${month}`

  // return dateObj.toLocaleDateString(
  //   (portalDe ? 'de-DE' : 'en-GB'),
  //   { weekday: 'short', day: '2-digit', month: '2-digit' }
  // )
}
</script>
